import React from "react"

import Layout from "../components/layout"
import Topicpath from "../components/topicpath"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock, faFolderOpen } from "@fortawesome/free-regular-svg-icons"
import { faChevronLeft, faChevronRight, faRedo } from "@fortawesome/free-solid-svg-icons"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { TwitterShareButton, TwitterIcon } from 'react-share'

import SEO from "../components/seo"
import ScriptTag from "react-script-tag"

import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"

import { graphql, Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Img from "gatsby-image/withIEPolyfill"

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import styled from 'styled-components';

import useContentfulImage from "../components/useContentfulImage"

export const query = graphql`
    query($id:String!) {
        contentfulBlogPost(id: {eq:$id}) {
            title
            publishDateJp:publishDate(formatString: "YYYY年MM月DD日")
            publishDate
            updatedAtJp:updatedAt(formatString: "YYYY年MM月DD日")
            updatedAt
            slug
            content {
                json 
            }
            category {
                category
                categorySlug
                id
            }
            eyecatch {
                fluid(maxWidth: 1600) {
                    ...GatsbyContentfulFluid_withWebp                
                }
                description
                file {
                    details {
                        image {
                            height
                            width
                        }
                    }
                    url
                }
            }
        }
    }
`

const options = {
    renderMark: {
        [MARKS.BOLD]: text => (
            <b><span>
                {text}
            </span></b>
        ),
    },
    renderNode: {
        [BLOCKS.HEADING_2]: (node, children) => {
            const title = String(children).replace('false,','')
            const titleI = title.split('').shift()
            const titleAll = title.split(',')
            const titleIOther = String(titleAll).replace(titleI, '')

            return <h2 id={`${titleAll}`}>
                <i>{titleI}</i>{titleIOther}
            </h2>
        },
        [BLOCKS.HEADING_3]: (node, children) => {
            const title = String(children).replace('false,','')
            const titleI = title.split('').shift()
            const titleAll = title.split(',')
            const titleIOther = String(titleAll).replace(titleI, '')

            return <h3 className="bar3_h3" id={`${titleAll}`}><span>
                {children}
            </span></h3>
        },
        [BLOCKS.HEADING_4]: (node, children) => {
            return <h4 className="bar3_h4"><span><i>
                {children}
            </i></span></h4>
        },
        [BLOCKS.HEADING_5]: (node, children) => (
            <h5 className="bar3_h5"><span><i>
                {children}
            </i></span></h5>
        ),
        [BLOCKS.EMBEDDED_ASSET]: node => (
            <Img fluid={useContentfulImage(node.data.target.fields.file["ja-JP"].url)}
                alt={
                    node.data.target.fields.description
                        ? node.data.target.fields.description["ja-JP"]
                        : node.data.target.fields.title["ja-JP"]
                } />
        ),
        [INLINES.HYPERLINK]: (node) => {
            const website_url = `/undefined/`
            const website_url_pinterest = `^(?=.*pinterest).*$`
            return <a href={node.data.uri} data-pin-do={node.data.uri.match(website_url_pinterest) ? 'embedPin' : ' '} target={`${node.data.uri.startsWith(website_url) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
        },
    },
    renderText: text => {
        return text.split("\n").reduce((children, textSegment, index) => {
            return [...children, index > 0 && <br key={index} />, textSegment]
        }, [])
    },
}

const summaryOptions = {
    renderNode: {
        [BLOCKS.HEADING_2]: (node, children) => {
            const title = String(children).replace('false,','')
            const titleAll = title.split(',')

            return <li className="l"><span><Link to={`#${titleAll}`}>
                {children}
            </Link></span></li>
        },
        [BLOCKS.HEADING_3]: (node, children) => {
            const title = String(children).replace('false,','')
            const titleAll = title.split(',')

            return <li className="m"><Link to={`#${titleAll}`}>
                {children}
            </Link></li>
        },
        [BLOCKS.HEADING_4]: (node, children) => {
            return <li className="s">
                {children}
            </li>
        },
        [BLOCKS.EMBEDDED_ASSET]: node => (
            ``
        ),
        [BLOCKS.PARAGRAPH]: node => (
            ``
        ),
    },
}

if (typeof window !== `undefined`) {

    const scriptPinterest = document.createElement("script");
    scriptPinterest.src = "//assets.pinterest.com/js/pinit.js";
    scriptPinterest.async = true;
    scriptPinterest.type = "text/javascript";

    const scriptRakuJs = document.createElement("script");
    scriptRakuJs.src = "//rws.a8.net/rakuten/ranking.js";
    scriptRakuJs.async = true;
    scriptRakuJs.type = "text/javascript";

    document.head.prepend(scriptPinterest,scriptRakuJs);


    const scriptRaku = document.createElement('script');
    scriptRaku.type = 'text/javascript';

    const textAlert = document.createTextNode(`var a8='a21031061650_3H7OPR_383JQQ_2HOM_BUB81';var rankParam='JiaDpEz7ShZwWdKpS6Z0egZIUhZIA';var bannerType='0';var bannerKind='item.fix.kind2';var frame='1';var ranking='1';var category='総合';`);
    scriptRaku.appendChild(textAlert);

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(scriptRaku);

    const img = document.createElement('img');
    img.src = "https://www16.a8.net/0.gif?a8mat=3H7OPR+383JQQ+2HOM+BS629";
}

const AccordionStyle = styled(Accordion)`
    box-shadow: none!important;
    .MuiButtonBase-root,
    .MuiAccordionDetails-root {
        padding: 0!important;
    }
    .MuiAccordionSummary-content {
        margin: 0!important;
    }
`

export default ({ location, data, pageContext }) => {
    const summaryJson = documentToReactComponents(data.contentfulBlogPost.content.json, summaryOptions)

    let pageTitleObj;

    if (pageContext.catslug.indexOf(`beginner1`) > -1) {
        pageTitleObj = `${data.contentfulBlogPost.title.replace(/\d日目：/g, "")}|一週間集中ファッション無料本格入門講座【基礎編】`
    } else if (pageContext.catslug.indexOf(`beginner2`) > -1) {
        pageTitleObj = `${data.contentfulBlogPost.title.replace(/\d日目：/g, "")}|一週間集中ファッション無料本格入門講座【応用編】`
    } else {
        pageTitleObj = `${data.contentfulBlogPost.title}`
    }

    const pageDescObj = pageContext.catslug.indexOf(`beginner1`) > -1
        ? `一週間集中ファッション無料入門講座　${data.contentfulBlogPost.title}。ファッション理論・法則をできるだけ体型的に科学的に深く解説する事を重視しているので、一生使えるファッション知識が効率的に身に付きます。最速で実用性のある知識が身に付きます。無料で学べる講座です。`
        : `${documentToPlainTextString(data.contentfulBlogPost.content.json).slice(0, 115).replace(/\r?\n/g, "")}…`

    return <div id="blogpost">
        <SEO
            pagetitle={pageTitleObj}
            pagedesc={pageDescObj}
            pagekeys={data.contentfulBlogPost.title}
            pagepath={location.pathname}
            blogimage={`https:${data.contentfulBlogPost.eyecatch.file.url}`}
            imageW={data.contentfulBlogPost.eyecatch.file.details.image.width}
            imageH={data.contentfulBlogPost.eyecatch.file.details.image.height}
        />
        <Layout>
            <div className="eyecatch">
                <figure>
                    <Img fluid={data.contentfulBlogPost.eyecatch.fluid} style={{ height: "100%" }} alt={data.contentfulBlogPost.eyecatch.description} />
                </figure>
                <h1 className="bar l"><i>lesson</i>{data.contentfulBlogPost.title}</h1>
            </div>
            <Topicpath pageUrl={location.pathname} blogTitle={data.contentfulBlogPost.title} />
            <article className="content">
                <div className="container">
                    <main>
                        <aside className="info">
                            <ul>
                                <li><time dateTime={data.contentfulBlogPost.publishDate}><FontAwesomeIcon icon={faClock} />{data.contentfulBlogPost.publishDateJp}</time></li>
                                <li><time dateTime={data.contentfulBlogPost.updatedAt}><FontAwesomeIcon icon={faRedo} />{data.contentfulBlogPost.updatedAtJp}</time></li>
                            </ul>

                            {data.contentfulBlogPost.category && (
                                <div className="cat">
                                    <FontAwesomeIcon icon={faFolderOpen} />
                                    <ul>
                                        {data.contentfulBlogPost.category.map(cat => (
                                            <li className={cat.categorySlug} key={cat.id}>
                                                <Link to={`/${cat.categorySlug}/`}>
                                                    {cat.category}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </aside>
                        <ul className="postsns">
                            <li>
                                <TwitterShareButton url={`https://henri-morhange.com/undefined/${data.contentfulBlogPost.slug}`} title={data.contentfulBlogPost.title} hashtag={`#aaaaa`}>
                                    <TwitterIcon size={32} round={true} />
                                </TwitterShareButton>
                            </li>
                            <li>
                                <OutboundLink href="https://instagram.com/henrimorhange?ref=badge" target="_blank" rel="noopener">
                                    <FontAwesomeIcon icon={faInstagram} />
                                </OutboundLink>
                            </li>
                        </ul>
                    
                        <div className="postbody">
                            {documentToReactComponents(data.contentfulBlogPost.content.json, options)}   
                        </div>
                        <ul className="postlink">
                            {pageContext.next && (
                                <li className="prev">
                                    <Link to={`/undefined/${pageContext.next.slug}/`} rel="prev">
                                        <FontAwesomeIcon icon={faChevronLeft} />
                                        <span>{pageContext.next.title}</span>
                                    </Link>
                                </li>
                            )}
                            {pageContext.previous && (
                                <li className="next">
                                    <Link to={`/undefined/${pageContext.previous.slug}/`} rel="next">
                                        <span>{pageContext.previous.title}</span>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </main>

                    <AccordionStyle TransitionProps={{ unmountOnExit: true }}  className="summary">
                        <AccordionSummary className="title">目次<KeyboardArrowDownIcon /></AccordionSummary>
                        <AccordionDetails>
                            <ul>
                                {summaryJson}
                            </ul>
                        </AccordionDetails>
                    </AccordionStyle>
                </div>
            </article>
        </Layout>
    </div>
}